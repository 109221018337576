<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body position-relative overflow-hidden">
            <div>Total Number Of Reviews</div>
            <div class="font-size-36 font-weight-bold text-dark mb-n2">
              {{ ratings.reviews }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card">
          <div class="card-body position-relative overflow-hidden">
            <div>Highest Rating</div>
            <div class="font-size-36 font-weight-bold text-dark mb-n2">
              {{ highestRating.toFixed(2) }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card">
          <div class="card-body position-relative overflow-hidden">
            <div>Average Rating</div>
            <div class="font-size-36 font-weight-bold text-dark mb-n2">
              {{ ratings.total_avg.toFixed(2) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="font-size-36 font-weight-bold text-dark mb-4">Reviews</div>
    <div>
      <a-table :columns="columns" :data-source="reviews">
        <template slot="rating" slot-scope="text">
          <span>{{ text }}</span>
        </template>

        <template slot="comment" slot-scope="text">
          <p>{{ text }}</p>
        </template>

        <template slot="created" slot-scope="date">
          <p>{{ date.toLocaleString() }}</p>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import ProfileMixin from "@/mixins/Profile";
import { get } from "lodash";

export default {
  mixins: [ProfileMixin],
  data() {
    return {
      columns: [
        {
          title: "Rating",
          dataIndex: "rate",
          scopedSlots: { customRender: "rating" },
        },
        {
          title: "Comment",
          dataIndex: "message",
          scopedSlots: { customRender: "comment" },
        },
        {
          title: "Created",
          dataIndex: "created",
          scopedSlots: { customRender: "created" },
        },
      ],
    };
  },
  computed: {
    reviews() {
      const account_reviews = get(this.account, "reviews", []);
      return account_reviews;
    },
    ratings() {
      const { reviews = 0, total_avg = 0 } = get(this.account, "ratings", {});
      return { reviews, total_avg };
    },
    highestRating() {
      const ratings = this.reviews.map((rv) => rv.rate);
      return Math.max.apply(null, ratings.length > 0 ? ratings : [0]);
    },
    averageRating() {
      const count = this.reviews.length;
      const total_ratings = this.reviews.reduce(
        (accumulator, c) => accumulator + c.rate,
        0
      );
      return total_ratings / count || 0;
    },
  },
  methods: {},
};
</script>
