<template>
  <facility-reviews-form />
</template>

<script>
import FacilityReviewsForm from "@/components/facilities/FacilityReviewsForm.vue";

export default {
  components: {
    FacilityReviewsForm,
  },
};
</script>
